import React from "react";
import Navbar from "../Components/Navbar";
import Navbar2 from "../Components/Navbar2";
import Table from "../Components/AccountTable";
import TimeChooser from "../Components/Credittime";
import { useState, useEffect } from 'react';
import "./Styles/Account.css";
import Cookies from "js-cookie"
import axios from "axios";
function Account() {
  const [creditBalance, setCreditBalance] = useState(0);

  useEffect(() => {
    // Fetch initial credit balance when component mounts
    fetchCreditBalance();
  }, []);
  const fetchCreditBalance = () => {
    const accessToken = Cookies.get('access_token');

    axios.get('http://localhost:5000/UserData', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(response => {
        setCreditBalance(response.data.Credit_Balance); // Assuming your API returns the credit balance
      })
      .catch(error => {
        console.error('Error fetching credit balance:', error);
      });
  };
  const handlePaymentSuccess = (newCreditBalance) => {
    // Update credit balance after successful payment
    setCreditBalance(newCreditBalance);
  };
  return (
    <div>
      <Navbar/>
      <Navbar2 />
      <h1 id="account">Account</h1>
      <TimeChooser onPaymentSuccess={handlePaymentSuccess}/>
      
      <h1 id="ledger-text">Previous Transactions</h1>
      <div id="Previous_ledger-container">
        <Table />
      </div>
    </div>
  );
}

export default Account;
