import React from "react";
import "./Styles/Googlesingin.css"
import config from "../config";
function GoogleSigninButton() {
    const API_URL = config.BASE_URL;

    const handleGoogleSignin = () => {
        window.location.href = API_URL+"/Login"; // Replace with your Flask server URL
      };
  return (
    <div className="container" onClick={handleGoogleSignin}>
      <img id="googlesymbol"
        src={require("../Assets/google-symbol.png")}
        alt="Google SIgnin"
      ></img>
      <h2 id ="signintext" >Sign in to Google</h2>
    </div>
  );
}

export default GoogleSigninButton;
