import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./Styles/Navbar.css";
import config from "../config";
function Navbar() {
  const [email, setEmail] = useState("");
  const [creditBalance, setCreditBalance] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const API_URL = config.BASE_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData) {
          
          const userData = JSON.parse(storedUserData);
          setEmail(userData.Email);
          setCreditBalance(userData.Credit_Balance);
          setDataLoaded(true);

          const accessToken = Cookies.get("access_token");
          const response = await axios.post(
            API_URL+"/UserData",
            {
              access_token: accessToken,
            },
            {
              withCredentials: true,
              mode: 'cors', 
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const userData1 = response.data;
          setEmail(userData1.Email);
          setCreditBalance(userData1.Credit_Balance);
          if (userData.Credit_Balance!==userData1.Credit_Balance)
            localStorage.setItem("userData", JSON.stringify(userData1));
          setDataLoaded(true);






          setEmail(userData.Email);
          setCreditBalance(userData.Credit_Balance);
          setDataLoaded(true);
        } else {
          const accessToken = Cookies.get("access_token");
          const response = await axios.post(
            API_URL+"/UserData",
            {
              access_token: accessToken,
            },
            {
              withCredentials: true,
              mode: 'cors', 
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const userData = response.data;
          setEmail(userData.Email);
          setCreditBalance(userData.Credit_Balance);
          localStorage.setItem("userData", JSON.stringify(userData));
          setDataLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [API_URL]); // Empty dependency array ensures fetchUserData runs only once on component mount

  const handleLogout = () => {
    localStorage.removeItem("userData");
    window.location.href = API_URL+"/Logout";
  };

  // Render Navbar once data is loaded
  return (
    <div className="Navbar-container">
      <div className="usericon">
        <img src={require("../Assets/user.png")} alt="user icon" />
        <h1>{dataLoaded ? email : ""}</h1>
      </div>
      <div className="right-items">
        <div className="creditminutes">
          <h1>{dataLoaded ? creditBalance : "0"}</h1>
          <img
            src={require("../Assets/back-in-time.png")}
            alt="credits icon"
          />
        </div>
        <div className="logout" onClick={handleLogout}>
          <img src={require("../Assets/logout.png")} alt="logout icon" />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
