import "./App.css";
import GoogleSigninButton from "./Components/GoogleSigninButton";
import LandVector from "./Assets/LandVector.svg"
function App() {
  
  return (
    <div className="App">
      <div className="Appheader">
        <h1>TRANSCRIBEME</h1>
      </div>
      <div className="googlesign">
        <GoogleSigninButton />
      </div>
      <img id = "vector-left" src={LandVector} alt="Vector"></img>
      <img id = "vector-right" src={LandVector} alt="Vector"></img>
      {/* <FloatingCloud/> */}

    </div>
  );
}

export default App;
