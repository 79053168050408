import React from "react";
import Navbar from "../Components/Navbar";
import Navbar2 from "../Components/Navbar2"
function ContactUs (){
    return (
        <div>
        <Navbar/>
        <Navbar2/>
        <h1 id="prev_upload">Contact Us</h1>
        </div>
    )
}

export default ContactUs;