import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Homepage from './Pages/HomePage';
import Account from './Pages/Account';
import ContactUs from './Pages/ContactUs';
import Upload from './Pages/Upload';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';

const router = new createBrowserRouter([
  {
    path:'/',
    element:<App/>
  },
  {
    path:'/Homepage',
    element:<Homepage/>
  },
  {
    path:'/Account',
    element:<Account/>

  },
  {
    path:'/ContactUs',
    element:<ContactUs/>
  },
  {
    path:'/Upload',
    element:<Upload/>
  }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router = {router}/>
);

reportWebVitals();
