import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import "./Styles/Upload.css";
import config from "../config";
const Uploads = () => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false); // State for error dialog
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message
  const navigate = useNavigate(); // Initialize useNavigate hook
  const API_URL = config.BASE_URL;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleUpload = async () => {
    if (!file || !title) {
      alert("Please select a file and enter a title.");
      return;
    }
    const accessToken = Cookies.get("access_token");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("videotitle", title);
    formData.append("access_token", accessToken);

    setIsUploading(true);
    try {
      const response = await axios.post(
        API_URL+"/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        }
      );

      console.log(response.data);
      if (response.data.Status === false) {
        setErrorMessage(response.data.Comments); // Set error message
        setErrorDialogOpen(true); // Open error dialog
      } else {
        setUploadProgress(0);
        setFile(null); // Reset file state
        setTitle(""); // Reset title state
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadProgress(0);
    } finally {
      setIsUploading(false);
    }
  };

  const handleClose = () => {
    setErrorDialogOpen(false); // Close error dialog
    setIsUploading(false);
    if (errorMessage === "") {
      navigate("/Homepage"); // Redirect to /Homepage using useNavigate
    }
  };

  return (
    <Box
      className="upload-container"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={2}
      border={1}
      borderColor="grey.500"
      borderRadius={2}
    >
      <input
        accept="video/*"
        style={{ display: "none" }}
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Choose File
        </Button>
      </label>
      {file && <Box mt={2}>{file.name}</Box>}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label="Enter video title"
        value={title}
        onChange={handleTitleChange}
      />
      <Button variant="contained" color="primary" onClick={handleUpload}>
        Upload
      </Button>

      <Dialog open={isUploading} onClose={handleClose}>
        <DialogTitle>
          {uploadProgress < 100 ? "Uploading" : "Upload Complete"}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body1">
              {uploadProgress < 100
                ? `Progress: ${uploadProgress}%`
                : "Video under processing. Download from Homepage"}
            </Typography>
            {uploadProgress < 100 && (
              <Box width="100%" mt={2}>
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Error dialog for specific error scenario */}
      <Dialog open={errorDialogOpen} onClose={handleClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{errorMessage}</Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Uploads;
