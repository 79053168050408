import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../Components/Navbar";
import Navbar2 from "../Components/Navbar2";
import "./Styles/Homepage.css";
import Table from "../Components/PreviousUploadTable";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

function Homepage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialAccessToken = searchParams.get("Authorization");

  // State to manage access token
  const [accessToken, setAccessToken] = useState(initialAccessToken);
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    // Function to fetch or set access token
    const fetchAccessToken = async () => {
      try {
        setTimeout(() => {
          setAccessToken(initialAccessToken);
          setIsLoading(false);
        }, 10); // Adjust timeout as needed
      } catch (error) {
        console.error("Error fetching access token:", error);
        setIsLoading(false);
      }
    };

    fetchAccessToken();
  }, [initialAccessToken]);

  useEffect(() => {
    // Save access token to cookies when component mounts or when it changes
    if (accessToken) {
      console.log(accessToken);
      Cookies.set("access_token", accessToken, { expires: 7 }); // Expires in 7 days (adjust as needed)
    }
  }, [accessToken]);

  // If loading, display loading state
  if (isLoading) {
    return <div>Loading...</div>; // You can replace this with a loader component
  }

  // Once access token is set and loading is complete, render the actual content
  return (
    <div>
      <Navbar />
      <Navbar2 />
      <h1 id="prev_upload">Previous Uploads</h1>
      <div id="Previousupload-container">
        <Table />
      </div>
    </div>
  );
}

export default Homepage;
