import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "./Styles/creditpicker.css"; // Make sure the path is correct for your CSS file
import RazorpayPayment from "./Razorpay";
import axios from "axios";
import Cookies from "js-cookie";
import Swal from 'sweetalert2'; // Import SweetAlert2
import { useNavigate } from "react-router-dom";
import config from "../config";
const TimeChooser = () => {
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [second, setSecond] = useState("");
  const [orderId, setOrderId] = useState('');
  const [totaltime, setTotalTime] = useState(0); // State to hold the total time in seconds
  const [amount, setAmount] = useState(0);
  const [showamount, setshowAmount] = useState(0); // State to hold the calculated amount
   // State to hold the calculated amount
  const [currency, setCurrency] = useState('INR'); // State to hold the currency
  const [showPayment, setShowPayment] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    calculateAmount();
  }, [second, hour, minute]);

  const handleHourChange = (event) => {
    setHour(event.target.value);
  };

  const handleMinuteChange = (event) => {
    setMinute(event.target.value);
  };

  const handleSecondChange = (event) => {
    setSecond(event.target.value);
  };

  const calculateAmount = () => {
    const hourNum = parseInt(hour) || 0;
    const minuteNum = parseInt(minute) || 0;
    const secondNum = parseInt(second) || 0;
    const totalSeconds = hourNum * 3600 + minuteNum * 60 + secondNum;
    setTotalTime(totalSeconds); // Update total time in seconds
    setAmount(totalSeconds * 0.5);
    setshowAmount(totalSeconds*0.5) // Update amount based on total time (factor of 0.5)
  };

  const handleBuyNow = () => {
    if (hour === '' && minute === '' && second === '') {
      Swal.fire({
        title: 'Error',
        text: 'Minimum 10 Minutes has to be bought',
        icon: 'failed',
        confirmButtonText: 'OK'
      })
      return;
    }
    if (hour<0 || minute<0 || second<0){
      Swal.fire({
        title: 'Error',
        text: 'Negative Numbers are not allowed',
        icon: 'failed',
        confirmButtonText: 'OK'
      }).then(handlePaymentSuccess)
      return;

    }

    const hourNum = parseInt(hour) || 0;
    const minuteNum = parseInt(minute) || 0;
    const secondNum = parseInt(second) || 0;
    const totalSeconds = hourNum * 3600 + minuteNum * 60 + secondNum;
    const API_URL = config.BASE_URL;


    axios.post(
      API_URL+'/Payment',
      {
        access_token: Cookies.get('access_token'), // Adjust as per your auth mechanism
        currency: "INR",
        totalSeconds: totalSeconds,
        amount: amount
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        const { id, amount, currency } = response.data;
        setOrderId(id);
        setAmount(amount);
        setCurrency(currency || 'INR');
        setShowPayment(true);
      })
      .catch((error) => {
        console.error('Error fetching payment details:', error);
      });
  };

  const handlePaymentSuccess = () => {

    setHour("");
    setMinute("");
    setSecond("");
    setTotalTime(0);
    setAmount(0);
    setShowPayment(false);

  };

  const handlePaymentFail = () => {
    setShowPayment(false);
  };

  return (
    <div className="Creditpicker">
      <h1>Buy Credits</h1>
      <div className="field">
      <TextField
        id="filled-hour"
        label="Hour"
        type="number"
        value={hour}
        onChange={handleHourChange}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ width: "6vw" }}
      />
      <div className="brk"></div>

      <TextField
        id="filled-minute"
        label="Minute"
        type="number"
        value={minute}
        onChange={handleMinuteChange}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ width: "6vw" }}
      />
      <div className="brk"></div>

      <TextField
        id="filled-second"
        label="Second"
        type="number"
        value={second}
        onChange={handleSecondChange}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ width: "5vw" }}
      />
    </div>
      

      <h1>Amount: {showamount}</h1> {/* Display dynamically calculated amount */}

      <Button
        variant="contained"
        onClick={handleBuyNow}
        sx={{ width: "7vw" }}
        disabled={showPayment} // Disable button while processing payment
      >
        Buy Now
      </Button>

      {showPayment && (
        <RazorpayPayment orderId={orderId} amount={amount} onPaymentSuccess={handlePaymentSuccess} onPaymentFail={handlePaymentFail} />
      )}
    </div>
  );
};

export default TimeChooser;
