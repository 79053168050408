import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import Cookies from "js-cookie";
import config from '../config';
export default function PaginationCommunityNoSnap({ accessToken }) {
  const [data, setData] = useState({ rows: [], columns: [] });
  const API_URL = config.BASE_URL;
  const fetchData = async () => {
    try {
        const response = await axios.post(
            API_URL+'/fetch_ledger',
            {
              access_token: Cookies.get("access_token"),
            },
            {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          
      const apiData = response.data;

      // Log to console if Status is false
      if (!apiData.Status) {
        console.log('Status is false in API response');
      }

      // Map Ledger_Data to rows for DataGrid
      const rows = apiData.Ledger_Data.map((item, index) => ({
        id: index,
        transaction_id: item.Transaction_id,
        amount_debited: item.Amount_Debited,
        credits_gained: item.Credits_gained,
        timestamp: item.Timestamp,
        status: item.Status,
      }));
      rows.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      setData((prevState) => ({
        ...prevState,
        rows: rows,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [accessToken]); // Re-fetch data if accessToken changes

  // Define the columns outside of fetchData to ensure they are always defined
  const columns = [
    { field: 'transaction_id', headerName: 'Transaction ID', width: 130, headerAlign: 'center', align: 'center' },
    { field: 'amount_debited', headerName: 'Amount Debited', width: 150 , headerAlign: 'center', align: 'center' },
    { field: 'credits_gained', headerName: 'Credits Gained', width: 150 , headerAlign: 'center', align: 'center' },
    { field: 'timestamp', headerName: 'Timestamp', width: 150 , headerAlign: 'center', align: 'center' },
    { field: 'status', headerName: 'Status', width: 120 , headerAlign: 'center', align: 'center' },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Button variant="contained" color="primary" onClick={fetchData} style={{ marginBottom: '10px' }}>
        Refresh Data
      </Button>
      <DataGrid
        rows={data.rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        
      />
    </div>
  );
}
