import React from "react";
import Navbar from "../Components/Navbar";
import Navbar2 from "../Components/Navbar2";
import Uploads from "../Components/UploadComponent";
function Upload() {
  return (
    <div>
      <Navbar />
      <Navbar2 />
      <h1 id="prev_upload">Upload Video</h1>
      <Uploads />
      
    </div>
  );
}

export default Upload;
