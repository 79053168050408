import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Button from '@mui/material/Button';
import Cookies from "js-cookie";
import "./Styles/PreviousUpload.css"
import config from '../config';
export default function PaginationCommunityNoSnap({ accessToken }) {
  const [data, setData] = useState({ rows: [], columns: [] });
  const API_URL = config.BASE_URL;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        API_URL+'/Videos',
        {
          access_token: Cookies.get("access_token"),
        },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const apiData = response.data;

      // Log to console if Status is false
      if (!apiData.Status) {
        console.log('Status is false in API response');
      }

      // Map Video_Data to rows for DataGrid
      let rows = apiData.Video_Data.map((item) => ({
        id: item.Id,
        credits_used: item.Credits_Used,
        email: item.Email,
        length: item.Length,
        srt_transcript_url: item.Srt_Transcript_Url,
        status: item.Status ? item.Status : 'Processing', // Replace null with 'Processing'
        video_name: item.Video_Name,
        vtt_transcript_url: item.Vtt_Transcript_Url,
        Timestamp: item.Timestamp,
      }));

      // Sort rows by Timestamp in descending order
      rows.sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));

      setData((prevState) => ({
        ...prevState,
        rows: rows,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [accessToken]); // Re-fetch data if accessToken changes

  // Custom cell renderer for buttons
  const renderButton = (params) => {
    const url = params.value;
    return (
      <Button 
        variant="contained" 
        color="primary" 
        style={{ minWidth: '150px' }}
        href={url || "#"} 
        disabled={!url}
        target="_blank" 
        rel="noopener noreferrer"
      >
        {url ? 'Download' : 'Unavailable'}
      </Button>
    );
  };

  // Define the columns outside of fetchData to ensure they are always defined
  const columns = [
    { field: 'credits_used', headerName: 'Credits Used', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'length', headerName: 'Length', width: 100, headerAlign: 'center', align: 'center'  },
    { 
      field: 'srt_transcript_url', 
      headerName: 'SRT Transcript', 
      width: 200, headerAlign: 'center', align: 'center' ,
      renderCell: renderButton 
    },
    { field: 'status', headerName: 'Status', width: 150 , headerAlign: 'center', align: 'center' },
    { field: 'video_name', headerName: 'Video Name', width: 200 , headerAlign: 'center', align: 'center' },
    { 
      field: 'vtt_transcript_url', 
      headerName: 'VTT Transcript', 
      width: 200, headerAlign: 'center', align: 'center' ,
      renderCell: renderButton 
    },
    { 
      field: 'Timestamp', 
      headerName: 'Timestamp', 
      width: 200, headerAlign: 'center', align: 'center' ,
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <Button variant="contained" color="primary" onClick={fetchData} style={{ marginBottom: '10px' }}>
        Refresh Data
      </Button>
      <DataGrid
        rows={data.rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
      />
    </div>
  );
}
