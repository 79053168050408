import React from "react";
import { useNavigate } from "react-router-dom";
import "./Styles/Navbar2.css";
function Navbar() {
  let navigate = useNavigate();
  const handleonclickHome = () =>{
    
      navigate("/Homepage");
    
  }
  const handleonclickAccount = () =>{
    
    navigate("/Account");
  
}
const handleonclickContactUs= () =>{
    
  navigate("/ContactUs");

}
const handleonclickUpload= () =>{
    
  navigate("/Upload");

}
  return (
    <div className="Navbar2-container">

      <div className="Home" onClick={handleonclickHome}>
        <h1>Home</h1>
      </div>
      <div className="Upload" onClick={handleonclickUpload}>
        <h1>Upload</h1>
      </div>
      
      <div className="Account" onClick={handleonclickAccount}>
        <h1>Account</h1>
      </div>

      <div className="ContactUs" onClick={handleonclickContactUs}>
        <h1>Contact Us</h1>
      </div>
    </div>
  );
}

export default Navbar;
