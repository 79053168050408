import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from "js-cookie";
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2'; // Import SweetAlert2
import config from '../config';
const RazorpayPayment = ({ orderId, amount, onPaymentSuccess, onPaymentFail }) => {
  const [loading, setLoading] = useState(true); // State for loading spinner
  const API_URL = config.BASE_URL;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      const options = {
        key: 'rzp_test_7mBE886r2Xg3aw', // Replace with your Razorpay key
        amount: amount * 100, // Amount in paise
        currency: 'INR', // Currency code
        name: 'TranscribeMe', // Company name or app name
        description: 'Minutes',
        order_id: orderId, // Product description
        image: 'vk.jpg', // Logo URL
        netbanking: true, // Enable netbanking
        prefill: {
          name: 'Sam Abraham Joshy', // Name of the payer
          email: 'samabraham2022@gmail.com', // Email of the payer
          contact: '9999999999', // Contact number of the payer
        },
        handler: function (response) {
          // Send payment success data to backend
          axios.post(API_URL+'/Paymentcomplete', {
            access_token: Cookies.get("access_token"),
            razorpay_payment_id: response.razorpay_payment_id,
            amount: options.amount, // Amount in paise
            currency: options.currency, // Currency code
            shopping_order_id: '21', // Order ID or any additional data
          })
            .then(res => {
              console.log(res.data);
              setLoading(false); // Stop the loading spinner
              Swal.fire({
                title: 'Payment Successful!',
                text: 'Your payment has been processed successfully.',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then(() => {
                onPaymentSuccess(); // Call the parent function to reset the form
              });
            })
            .catch(error => {
              console.error('Error while processing payment:', error);
              setLoading(false); // Stop the loading spinner in case of error
              Swal.fire({
                title: 'Payment Failed!',
                text: 'There was an error processing your payment. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            });
        },
        modal: {
          ondismiss: function () {
            setLoading(false); // Stop the loading spinner when modal is dismissed
            Swal.fire({
              title: 'Payment Cancelled',
              text: 'You have closed the payment window.',
              icon: 'info',
              confirmButtonText: 'OK'
            }).then(() => {
              onPaymentFail(); // Call the parent function to reset the form
            });
          }
        }
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [orderId, amount, onPaymentSuccess, onPaymentFail,API_URL]);

  return (
    <div>
      {loading && (
        <div className="spinner-container">
          <ClipLoader color="#36d7b7" loading={loading} size={150} />
        </div>
      )}
    </div>
  );
};

export default RazorpayPayment;
